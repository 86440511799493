import React from "react";


const Gallery = () => {

    return (
        <div>
            <sub>coming soon</sub>
        </div>
    )
}

export default Gallery;